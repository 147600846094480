.App {
  text-align: center;
  Zoom: 75%;
  padding-top: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header-row {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.card-title{
  height: 100px;
  margin: 0px;
}

.btn {
  margin: 10px;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 20px;
}

.btn-primary {
  background-color: #282c34;
  color: palegreen;
}