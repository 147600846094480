@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;1,700&display=swap");
* {
  box-sizing: border-box;
}

h1 {
  font-weight: normal;
  font-size: 3rem;
  margin-top: 3rem;
}

.countdown-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px ;
}

.countdown-container-near {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px ;
  color: red;
}

.big-text {
  font-weight: bold;
  font-size: 3rem;
  line-height: 1;
  margin: 1rem 2rem;
}

.countdown-el {
  text-align: center;
}

.countdown-el span {
  font-size: 1.5rem;
}

.tile {
    height: 350px ;
    width: 700px;
    color: palegreen;
    background-color: black;
    border-radius: 35px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.tile-empty {
    height: 350px ;
    width: 700px;
    color: palegreen;
    background-color: #282c34;
    border-radius: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}